import React from 'react';

import Layout from '../../layouts/backendLayout';
import Authentication from '../../components/authentication';
import NotificationProvider from '../../components/notificationProvider';

import GlobalStyles from '../../styles/backend/global';

const LeadsPage = ({ location }) => {
  return (
    <GlobalStyles>
      <Authentication>
        {({ user, login, logout, hasToken, getToken }) => (
          <NotificationProvider>
            {({ growl }) => (
              <Layout
                user={user}
                login={login}
                logout={logout}
                growl={growl}
                location={location}
              >
                <h1>Leads</h1>
                <p>This is the leads page.</p>
              </Layout>
            )}
          </NotificationProvider>
        )}
      </Authentication>
    </GlobalStyles>
  );
};

export default LeadsPage;
